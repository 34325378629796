/*--- Specification ---*/

@import 'fonts';

$black: #000;

body {
	font:400 16px 'Raleway',sans-serif;
	padding: 0;
	margin: 0;
	letter-spacing: 0em;
	color: #828181;	
}
.main {
	overflow-x: hidden;
}
div, p, form, input, a, span, button {
	box-sizing: border-box;
}
p {
	margin: 0;
	padding: 0;
}
ul, li {
	display: block;
	margin: 0;
	padding: 0;
}
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	font-weight: 300;
	color: $black;
}
h1 {	
	font-size: 48px;
	color: #b1b1b1;
}
h2 {
	font-size: 40px;
}
h3 {
	font-size: 30px;
}
h4 {
	font-size: 24px;
}
h5 {
	font-size: 18px;
}
a, a:hover, a:active {
	text-decoration: none;
}
input, input:hover, input:focus, input:active,
button, button:hover, button:focus, button:active
textarea, textarea:hover, textarea:focus, textarea:active {
	outline: none;
}
.knob {
	width: 140px;
	border: 2px solid #92bf1f;
	color: #92bf1f;
	border-radius: 20px;
	display: inline-block;
	text-align: center;
	padding-top: 10px;
	padding-bottom: 10px;
	transition: all ease 0.3s;
}
.knob:hover {
	background: #92bf1f;
	color: #fff;
	border: 2px solid transparent;
}
.shadow {
	box-shadow: 0 0 30px 0px rgba(33, 115, 155, 0.3) !important;   
}
.lightboxOverlay {
    filter: alpha(Opacity=40);
    opacity: .4;
}

/*-- Menu Hamburger --*/

input#humburger {
  display: none;
}
label.humburger {
    z-index: 9999;
    position: absolute;
    display: inline;
    cursor: pointer;
    text-align: left;
    top: -13px;
    left: 25px;
}
.click span,
.click span:before,
.click span:after {
    background: #000;
    border-radius: 1px;
    position: absolute;
    height: 4px;
    width: 22px;
}
.click span:first-child {
    top: 2px;
}
.click span:nth-child(2) {
    top: 10px;
}
.click span:nth-child(3) {
    top: 18px;
}
.click {
	padding: 2px;
    width: 28px;
    height: 28px;
    position: absolute;
    top: 5px;
    z-index: 1;
    display: block;
    border: 1px solid transparent;
    border-radius: 3px;
}
.active-click {
	border: 1px solid #fff;
	cursor: pointer;
	span,
	span:before,
	span:after {
	    background: #fff;
	}
}
.menu-open {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background: #92bf1f;
    top: -150%;
    left: 0;
    z-index: 999;
    padding-top: 40px;
    transition: all 0.5s ease;
}
.menu-open_show {
    top: 0;
}
.menu-humburger {
    flex-direction: column;
    align-items: center;
}
.menu-humburger li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.17);
    width: 70%;
    margin: auto;
    padding: 20px 0;
    text-align: center;           
}
.menu-humburger li a {
    display: inline-block;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    position: relative;
    opacity: 0.8;
    transition: all 0.5s ease;
}