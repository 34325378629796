@charset "UTF-8";
/*--- Specification ---*/
@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-light.eot");
  src: url("../fonts/raleway-light.woff2") format("woff2"), url("../fonts/raleway-light.woff") format("woff"), url("../fonts/raleway-light.ttf") format("truetype"), url("../fonts/raleway-light.svg#raleway-light") format("svg"), url("../fonts/raleway-light.eot?#iefix") format("embedded-opentype");
  font-weight: 300; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-regular.eot");
  src: url("../fonts/raleway-regular.woff2") format("woff2"), url("../fonts/raleway-regular.woff") format("woff"), url("../fonts/raleway-regular.ttf") format("truetype"), url("../fonts/raleway-regular.svg#raleway-regular") format("svg"), url("../fonts/raleway-regular.eot?#iefix") format("embedded-opentype");
  font-weight: 400; }

@font-face {
  font-family: 'Raleway';
  src: url("../fonts/raleway-medium.eot");
  src: url("../fonts/raleway-medium.woff2") format("woff2"), url("../fonts/raleway-medium.woff") format("woff"), url("../fonts/raleway-medium.ttf") format("truetype"), url("../fonts/raleway-medium.svg#raleway-medium") format("svg"), url("../fonts/raleway-medium.eot?#iefix") format("embedded-opentype");
  font-weight: 500; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-regular.eot");
  src: url("../fonts/montserrat-regular.woff2") format("woff2"), url("../fonts/montserrat-regular.woff") format("woff"), url("../fonts/montserrat-regular.ttf") format("truetype"), url("../fonts/montserrat-regular.svg#montserrat-regular") format("svg"), url("../fonts/montserrat-regular.eot?#iefix") format("embedded-opentype");
  font-weight: 400; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-semibold.eot");
  src: url("../fonts/montserrat-semibold.woff2") format("woff2"), url("../fonts/montserrat-semibold.woff") format("woff"), url("../fonts/montserrat-semibold.ttf") format("truetype"), url("../fonts/montserrat-semibold.svg#montserrat-semibold") format("svg"), url("../fonts/montserrat-semibold.eot?#iefix") format("embedded-opentype");
  font-weight: 600; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/montserrat-bold.eot");
  src: url("../fonts/montserrat-bold.woff2") format("woff2"), url("../fonts/montserrat-bold.woff") format("woff"), url("../fonts/montserrat-bold.ttf") format("truetype"), url("../fonts/montserrat-bold.svg#montserrat-bold") format("svg"), url("../fonts/montserrat-bold.eot?#iefix") format("embedded-opentype");
  font-weight: 700; }

body {
  font: 400 16px 'Raleway',sans-serif;
  padding: 0;
  margin: 0;
  letter-spacing: 0em;
  color: #828181; }

.main {
  overflow-x: hidden; }

div, p, form, input, a, span, button {
  box-sizing: border-box; }

p {
  margin: 0;
  padding: 0; }

ul, li {
  display: block;
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000; }

h1 {
  font-size: 48px;
  color: #b1b1b1; }

h2 {
  font-size: 40px; }

h3 {
  font-size: 30px; }

h4 {
  font-size: 24px; }

h5 {
  font-size: 18px; }

a, a:hover, a:active {
  text-decoration: none; }

input, input:hover, input:focus, input:active,
button, button:hover, button:focus, button:active
textarea, textarea:hover, textarea:focus, textarea:active {
  outline: none; }

.knob {
  width: 140px;
  border: 2px solid #92bf1f;
  color: #92bf1f;
  border-radius: 20px;
  display: inline-block;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  transition: all ease 0.3s; }

.knob:hover {
  background: #92bf1f;
  color: #fff;
  border: 2px solid transparent; }

.shadow {
  box-shadow: 0 0 30px 0px rgba(33, 115, 155, 0.3) !important; }

.lightboxOverlay {
  filter: alpha(Opacity=40);
  opacity: .4; }

/*-- Menu Hamburger --*/
input#humburger {
  display: none; }

label.humburger {
  z-index: 9999;
  position: absolute;
  display: inline;
  cursor: pointer;
  text-align: left;
  top: -13px;
  left: 25px; }

.click span,
.click span:before,
.click span:after {
  background: #000;
  border-radius: 1px;
  position: absolute;
  height: 4px;
  width: 22px; }

.click span:first-child {
  top: 2px; }

.click span:nth-child(2) {
  top: 10px; }

.click span:nth-child(3) {
  top: 18px; }

.click {
  padding: 2px;
  width: 28px;
  height: 28px;
  position: absolute;
  top: 5px;
  z-index: 1;
  display: block;
  border: 1px solid transparent;
  border-radius: 3px; }

.active-click {
  border: 1px solid #fff;
  cursor: pointer; }
  .active-click span,
  .active-click span:before,
  .active-click span:after {
    background: #fff; }

.menu-open {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: #92bf1f;
  top: -150%;
  left: 0;
  z-index: 999;
  padding-top: 40px;
  transition: all 0.5s ease; }

.menu-open_show {
  top: 0; }

.menu-humburger {
  flex-direction: column;
  align-items: center; }

.menu-humburger li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
  width: 70%;
  margin: auto;
  padding: 20px 0;
  text-align: center; }

.menu-humburger li a {
  display: inline-block;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.3em;
  position: relative;
  opacity: 0.8;
  transition: all 0.5s ease; }

/*-- First section "HEADER" --*/
.header {
  background: #d5ebf8 url(../img/offer-bg.jpg) no-repeat top center/cover;
  padding-top: 10px;
  position: relative; }

.main-menu {
  padding-top: 5px; }
  .main-menu li:after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: #92bf1f;
    opacity: 0;
    transition: all ease 0.5s; }
  .main-menu li:hover:after {
    opacity: 1; }
  .main-menu li a,
  .main-menu li a:hover {
    color: #000; }

.phone,
.mail {
  color: #000;
  display: inline-block; }

.phone {
  font: 600 18px 'Montserrat', sans-serif;
  margin-bottom: 5px; }

.phone:hover,
.mail:hover {
  color: #000;
  opacity: 0.6; }

.offer {
  position: relative;
  margin-top: 40px;
  padding-top: 240px;
  background-image: url(../img/offerT-bg.png);
  background-position: -8px;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 220px; }

.offer:after,
.offer:before {
  content: '';
  display: block;
  position: absolute;
  top: 0; }

.offer:after {
  background-image: url(../img/Knife.png);
  background-position: top;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: -115px;
  width: 115px;
  height: 800px; }

.offer:before {
  background-image: url(../img/fork.png);
  background-position: top;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: -155px;
  width: 140px;
  height: 785px; }

.offer__title {
  width: 100%;
  max-width: 620px;
  margin: auto;
  line-height: 1em; }

.offer__subtitle {
  width: 100%;
  max-width: 550px;
  margin: auto;
  font-size: 18px;
  color: #000;
  padding-top: 35px;
  padding-bottom: 45px; }

.offer__knob {
  width: 300px;
  font-size: 25px;
  font-weight: 500;
  border-radius: 30px;
  padding-top: 13px;
  padding-bottom: 13px; }

.mint {
  position: absolute;
  top: 30px;
  right: -500px; }

.seeds {
  bottom: -75px;
  left: -500px;
  position: absolute; }

/*-- Second section "About" --*/
.about {
  padding-top: 80px; }

.a-text {
  padding-top: 25px; }

.a-knob {
  margin-top: 25px;
  width: 200px; }

/*-- Third section "Privelege" --*/
.privilege {
  padding-top: 80px;
  padding-bottom: 80px; }

.p-subtitle {
  font-weight: 300;
  font-size: 24px;
  padding-top: 5px;
  margin-bottom: 45px; }

.p-list {
  padding-bottom: 15px; }

.p-fact {
  font: 400 45px 'Montserrat',sans-serif;
  color: #35a7df; }
  .p-fact span {
    display: inline-block;
    width: 235px; }

.p-fact_everyday span {
  display: inline-block;
  width: 135px; }

/*-- Fourth section "Excellence" --*/
.excellence {
  background: url(../img/e-bg.jpg) no-repeat top center/cover;
  padding: 40px 0; }

.e-item {
  position: relative;
  margin-top: 55px; }

.e-item__number {
  position: absolute;
  font: 700 60px 'Montserrat',sans-serif;
  color: #35a7df;
  top: -35px; }

.e-item__title {
  display: inline-block;
  padding-bottom: 20px; }

.e-item__title,
.e-item__text {
  margin-left: 55px; }

.e-item__text {
  line-height: 1.5em; }
  .e-item__text span {
    font: 400 20px 'Montserrat',sans-serif;
    color: #35a7df; }

.e-item_four .e-item__text {
  position: relative; }
  .e-item_four .e-item__text span {
    display: inline-block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #35a7df;
    position: absolute;
    top: 10px;
    left: 0; }

.e-item_four .e-item__text:nth-child(4),
.e-item_four .e-item__text:nth-child(5) {
  padding-left: 15px;
  margin-top: 5px; }

.e-tomato {
  position: relative; }

.tomato {
  position: absolute;
  right: -130px;
  top: -120px; }

.rosemary {
  position: absolute;
  left: -145px;
  bottom: -130px; }

/*-- Fifth section "Sentence" --*/
.sentence {
  padding-top: 80px;
  padding-bottom: 85px; }

.s-title {
  padding-bottom: 25px; }

.s-card {
  padding: 25px 20px 30px 20px; }

.s-card__title {
  color: #828181;
  width: 100%;
  max-width: 300px;
  margin: 20px auto; }

/*-- Sixth section "Customers" --*/
.customers {
  padding-bottom: 85px; }

.customers-title {
  padding-bottom: 25px; }

.reviews {
  width: 100%;
  max-width: 300px;
  margin: auto; }

.reviews__slider {
  position: relative; }

.slick-arrow {
  background: transparent;
  border: none;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  z-index: 1;
  cursor: pointer;
  transition: all ease 0.3s; }

.slick-prev {
  left: -50px; }

.slick-next {
  right: -50px; }

.slick-prev:hover {
  left: -53px; }

.slick-next:hover {
  right: -53px; }

.slick-disabled {
  opacity: 0.3; }

.customers-logo {
  padding-left: 40px;
  flex-wrap: wrap; }

.customers-logo__img {
  width: 33%;
  margin-bottom: 20px; }
  .customers-logo__img img {
    max-width: 100%; }

.customers-logo__img:last-child {
  margin-bottom: 0; }

.customers-link {
  border-bottom: 1px solid #828181;
  transition: all ease 0.3s;
  display: inline-block;
  margin-top: 30px;
  color: #828181; }

.customers-link:hover {
  border-bottom: 1px solid transparent;
  color: #828181; }

/*-- Seventh section "FOOTER" --*/
.footer {
  padding-top: 15px;
  padding-bottom: 15px;
  border-top: 2px solid #92bf1f;
  background: #f3f3f3; }
  .footer .phone,
  .footer .mail {
    color: #828181; }
  .footer .container {
    position: relative; }

.footer-nav {
  border-bottom: 2px solid #cfcfcf;
  padding-bottom: 15px;
  position: relative; }

.footer-nav__menu li {
  margin-right: 40px; }

.footer-nav__menu a {
  color: #828181; }

.footer-nav__menu a:hover {
  opacity: 0.5; }

.law {
  padding-top: 15px;
  font-size: 14px; }

.law__link {
  color: #828181; }

.leaf {
  position: absolute;
  top: -245px;
  right: -370px; }

/*Modal Windows*/
.arcticmodal-container_i {
  width: 100%;
  max-width: 820px; }

.arcticmodal-container_i2 {
  padding: 0; }

.box-modal {
  position: relative;
  padding: 50px 130px;
  background: #fff; }

.box-modal_close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer; }
  .box-modal_close img {
    transition: all ease 0.5s; }

.box-modal_close:hover img {
  opacity: 0.5;
  -moz-transform: rotate(180deg);
  /* Для Firefox */
  -ms-transform: rotate(180deg);
  /* Для IE */
  -webkit-transform: rotate(180deg);
  /* Для Safari, Chrome, iOS */
  -o-transform: rotate(180deg);
  /* Для Opera */
  transform: rotate(180deg); }

.modal-title {
  margin-bottom: 50px; }

.modal-form span {
  position: relative;
  display: block; }

.modal-form input {
  margin-bottom: 25px; }

.modal-form textarea {
  resize: none; }

.modal-form input.error {
  border-color: red; }

label.error {
  position: absolute;
  bottom: -25px;
  left: 0;
  font-size: 12px;
  color: red; }

.form-control {
  padding: 0;
  width: 100%;
  padding-left: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  height: auto; }

.form-control:focus {
  border-color: #92bf1f;
  outline: 0;
  box-shadow: none; }

.f-knob {
  margin-top: 45px;
  background: transparent;
  cursor: pointer; }

/*special style*/
.header-secondary {
  background: #fff; }
  .header-secondary .navigation {
    border-bottom: 2px solid #92bf1f;
    padding-bottom: 15px; }

/*-- CONTACTS PAGE -- Second section "Communications" --*/
.communications {
  padding-top: 50px;
  padding-bottom: 125px; }
  .communications h4 img {
    padding-bottom: 21px;
    margin-right: 20px;
    margin-left: -10px; }
  .communications .container {
    position: relative; }

.olive {
  position: absolute;
  bottom: -85px;
  right: -145px; }

.communications-title {
  padding-bottom: 30px; }

address {
  margin-bottom: 35px; }

.c-phone .phone,
.c-phone .mail {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin-bottom: 35px; }

.props__list li {
  width: 100%;
  max-width: 395px;
  margin-bottom: 20px; }

.map {
  margin-top: 20px;
  margin-bottom: 40px; }

.appeal__title {
  padding-bottom: 23px; }

.appeal__subteatle {
  font-size: 24px;
  color: #000;
  margin-bottom: 38px; }

.appeal__knob {
  width: 200px; }

/*-- CLIENTAGE PAGE -- Second section "Clients" --*/
.clients {
  padding-top: 60px;
  padding-bottom: 140px; }
  .clients .container {
    position: relative; }

.boletus {
  position: absolute;
  bottom: -115px;
  right: -180px; }

.clients-title {
  padding-bottom: 45px; }

.clients-info {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid #dddddd; }

.clients-info_last {
  padding-bottom: 0;
  border: none; }

.clients-info__text {
  padding-left: 80px; }

.clients-info__logo {
  border: 1px solid #dddddd;
  min-width: 200px;
  text-align: center;
  line-height: 160px; }

.clients-text {
  margin-top: 70px; }

/*-- SERVICES PAGE -- First section "HEADER" --*/
.proffer {
  padding-bottom: 80px;
  padding-top: 60px; }

.proffer-title {
  line-height: 1.1em; }

.proffer-text {
  padding-top: 30px; }

/*-- SERVICES PAGE -- Second section "Case" --*/
.case {
  background: url(../img/e-bg.jpg) no-repeat top center/cover;
  padding-top: 40px;
  padding-bottom: 55px; }
  .case .e-item__number {
    top: -40px; }
  .case .e-item__text {
    font: 300 20px 'Raleway',sans-serif;
    color: #000;
    max-width: 400px; }
  .case .container {
    position: relative; }

.yummy {
  position: absolute;
  top: 50%;
  margin-top: -275px;
  left: -260px; }

/*-- SERVICES PAGE -- Second section "Formats" --*/
.formats {
  padding-top: 80px;
  padding-bottom: 40px; }

.f-tab {
  padding: 45px;
  border: 1px solid #92bf1f;
  margin-top: 35px;
  margin-bottom: 40px; }
  .f-tab th, .f-tab td {
    font-size: 14px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #e8e8e8;
    display: inline-block !important;
    vertical-align: top; }
  .f-tab th {
    width: 375px;
    padding-left: 80px;
    font-weight: 400;
    position: relative; }
    .f-tab th Img {
      position: absolute;
      top: 50%;
      margin-top: -12px;
      left: 20px; }
  .f-tab td {
    width: 170px;
    text-align: center;
    margin-left: 44px; }
    .f-tab td span {
      display: block;
      padding-bottom: 18px; }
  .f-tab td.certification {
    width: 185px; }
  .f-tab .tab-icon {
    display: inline-block;
    width: 40px;
    background: red;
    height: 40px; }

th.tab-title,
td.tab-title {
  font-size: 18px; }

td.tab-plus {
  font-size: 25px;
  padding-bottom: 5px; }

.f-knob {
  font-weight: 500;
  font-size: 20px;
  width: 200px;
  border-radius: 30px; }

/*-- SERVICES PAGE -- Second section "Demand" --*/
.demand {
  padding-bottom: 80px; }
  .demand .container {
    position: relative; }

.tomatoTwo {
  position: absolute;
  top: 0;
  left: -370px; }

.demand-title {
  padding-bottom: 30px; }

.d-content {
  box-shadow: inset 0 0 0 1px #e8e8e8; }

.d-cafe,
.d-buffet,
.d-canteen {
  border-bottom: 1px solid #e8e8e8; }

.d-cafe-slider a {
  max-height: 345px;
  display: inline-block; }

.d-cafe-slider .slick-arrow {
  background: #000;
  margin-top: -20px;
  padding: 8px 0;
  text-align: center;
  width: 50px;
  opacity: 0.7; }

.d-cafe-slider .slick-prev {
  left: 0; }

.d-cafe-slider .slick-next {
  right: 0; }

.d-cafe-slider .slick-prev:hover {
  left: 0;
  padding-right: 3px; }

.d-cafe-slider .slick-next:hover {
  right: 0;
  padding-left: 3px; }

.d-cafe-slider .slick-disabled {
  opacity: 0.3; }

.d-info {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 90px; }
  .d-info p {
    padding-bottom: 15px; }
    .d-info p span {
      color: #000; }

.d-info__privilege {
  color: #000; }

.d-info__title {
  padding-bottom: 20px; }

.d-info__privilege span,
.d-info__making span {
  display: block;
  padding-bottom: 15px; }

.d-info__list {
  line-height: 1.4em; }
  .d-info__list span {
    display: inline-block;
    width: 7px;
    height: 7px;
    background: #92bf1f;
    margin-right: 8px;
    border-radius: 50%; }

.d-buffet .d-info,
.d-cantina .d-info {
  padding-left: 30px;
  padding-right: 10px; }

/*-----Media queries-----*/
@media (min-width: 1200px) {
  .container {
    max-width: 1180px; } }

/*992px - 1200px*/
@media screen and (max-width: 1199px) {
  /*-- First section "HEADER" --*/
  .main-menu,
  .phone,
  .mail {
    font-size: 14px; }
  .knob {
    width: 100%;
    max-width: 140px; }
  .offer {
    padding-top: 170px;
    padding-bottom: 146px; }
  .offer:before {
    left: -110px;
    width: 110px;
    height: 675px; }
  .offer:after {
    right: -100px;
    width: 103px;
    height: 675px;
    margin-top: 10px; }
  h1 {
    font-size: 44px; }
  .offer__knob {
    max-width: 300px; }
  /*-- Second section "About" --*/
  .a-knob,
  .f-knob,
  .appeal__knob {
    max-width: 200px; }
  /*-- Fourth section "Excellence" --*/
  .tomato,
  .rosemary {
    display: none; }
  /*-- Fifth section "Sentence" --*/
  h4.s-card__title {
    font-size: 20px; }
  .s-card__pic {
    max-width: 325px !important;
    width: 100%  !important;
    margin: auto; }
  /*-- Sixth section "Customers" --*/
  .customers-logo__img {
    width: 30%;
    margin-left: 15px; }
  /*--- SERVICES PAGE ---*/
  h2.proffer-title {
    font-size: 32px;
    margin-top: 20px; }
  .proffer-text {
    padding-top: 15px; }
  .proffer {
    padding-bottom: 40px;
    padding-top: 40px; }
  .yummy {
    left: -375px; }
  .f-tab {
    padding: 15px;
    padding-top: 45px;
    padding-bottom: 45px;
    width: 100%;
    overflow: scroll; }
    .f-tab th {
      width: 300px;
      padding-left: 60px; }
      .f-tab th img {
        left: 10px;
        margin-top: -17px; }
    .f-tab td, .f-tab th {
      font-size: 12px;
      padding-top: 15px;
      padding-bottom: 10px; }
    .f-tab td {
      width: 160px;
      text-align: center;
      margin-left: 30px; }
    .f-tab table {
      width: 900px; }
  td.tab-title,
  th.tab-title {
    font-size: 14px; }
  .f-knob {
    margin-top: 0; } }

/*768px - 992px*/
@media screen and (max-width: 992px) {
  /*-- First section "HEADER" --*/
  .offer:before,
  .offer:after {
    display: none; }
  /*-- Second section "About" --*/
  .about {
    padding-top: 40px; }
  .a-title {
    margin-top: 20px; }
  /*-- Third section "Privelege" --*/
  h4.p-list {
    font-size: 14px; }
  .p-fact {
    font-size: 35px; }
  .p-fact span {
    width: 100%; }
  .privilege {
    padding-top: 40px;
    padding-bottom: 40px; }
  .sentence {
    padding-top: 40px;
    padding-bottom: 45px; }
  /*-- Sixth section "Customers" --*/
  .customers-link {
    margin-bottom: 30px; }
  .customers-logo {
    padding-left: 0; }
  .customers {
    padding-bottom: 45px; }
  /*-- Seventh section "FOOTER" --*/
  .footer-nav__menu {
    justify-content: space-around; }
  .footer-nav__menu li {
    margin-right: 0; }
  .footer .mail, .footer .phone {
    display: block;
    max-width: 130px;
    margin: auto; }
  .footer .phone {
    margin-top: 15px; }
  .box-modal {
    padding: 50px 100px;
    width: 90%;
    margin: auto; }
  /*--- SERVICES PAGE ---*/
  .yummy {
    display: none; }
  .case .e-item__text {
    font-size: 18px; }
  .formats {
    padding-top: 40px; }
  .slick-slide img {
    margin: auto; }
  .d-info {
    width: 90%;
    max-width: 480px;
    margin: auto;
    padding-right: 0; }
  .d-buffet .d-info,
  .d-cantina .d-info {
    padding-left: 0;
    padding-right: 0; }
  .demand {
    padding-bottom: 40px; } }

/*576px - 768px*/
@media screen and (max-width: 767px) {
  /*-- First section "HEADER" --*/
  .phone,
  .mail {
    display: block;
    max-width: 130px;
    margin: auto; }
  .offer {
    background-image: none;
    padding-top: 80px;
    padding-bottom: 100px; }
  /*-- Third section "Privelege" --*/
  h4.p-list {
    font-size: 24px; }
  .p-fact {
    font-size: 45px;
    margin-bottom: 20px; }
  .p-subtitle {
    font-size: 18px;
    margin-bottom: 30px; }
  /*-- Sixth section "Customers" --*/
  .customers-logo__img {
    width: 45%;
    margin-left: 0; }
  .customers-logo__img:nth-child(even) {
    margin-left: 20px; }
  .box-modal {
    padding: 50px 70px; }
  .communications .mail, .communications .phone {
    max-width: 100%;
    margin-bottom: 20px; }
  .communications .props {
    margin-top: 20px; }
  .olive {
    display: none; }
  .communications {
    padding-top: 40px;
    padding-bottom: 40px; }
  .clients-info {
    -webkit-flex-direction: column;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -o-flex-direction: column;
    flex-direction: column; }
  .clients-info__text {
    padding-left: 0;
    text-align: center;
    margin-top: 20px; }
  .clients-title {
    text-align: center; } }

/*320px - 576px*/
@media screen and (max-width: 576px) {
  /*-- First section "HEADER" --*/
  h1 {
    font-size: 35px;
    line-height: 1.3em; }
  .offer {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 0; }
  /*-- Sixth section "Customers" --*/
  .slick-prev {
    left: 0; }
  .slick-next {
    right: 0; }
  .slick-prev:hover {
    left: -3px; }
  .slick-next:hover {
    right: -3px; }
  /*-- Seventh section "FOOTER" --*/
  .footer-nav__menu {
    flex-direction: column;
    align-items: center; }
    .footer-nav__menu li {
      margin-bottom: 15px; }
  .box-modal {
    padding: 50px 10px;
    width: 100%; }
  /*--- SERVICES PAGE ---*/
  .f-tab {
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px; }
  td.tab-title,
  th.tab-title {
    font-size: 12px; } }
