/*-- SERVICES PAGE -- First section "HEADER" --*/

.proffer {	
	padding-bottom: 80px;
	padding-top: 60px;
}
.proffer-title {
	line-height: 1.1em;
}
.proffer-text {
	padding-top: 30px;
}

/*-- SERVICES PAGE -- Second section "Case" --*/

.case {
	background: url(../img/e-bg.jpg) no-repeat top center/cover;
	padding-top: 40px;
	padding-bottom: 55px;

	.e-item__number {
		top: -40px;
	}

	.e-item__text {
		font:300 20px 'Raleway',sans-serif;
		color: $black;
		max-width: 400px;
	}
	.container {
		position: relative;
	}
}
.yummy {
	position: absolute;
    top: 50%;
    margin-top: -275px;
    left: -260px;
}

/*-- SERVICES PAGE -- Second section "Formats" --*/

.formats {
	padding-top: 80px;
	padding-bottom: 40px;
}
.f-tab {
	padding: 45px;
	border: 1px solid #92bf1f;
	margin-top: 35px;
	margin-bottom: 40px;
	
	th,td {
		font-size: 14px;
		padding-top: 15px;
		padding-bottom: 15px;
		border-top: 1px solid #e8e8e8;	
		display: inline-block !important;
		vertical-align: top;		
	}
	th {
		width: 375px;
		padding-left: 80px;
		font-weight: 400;
		position: relative;
		Img {
			position: absolute;
		    top: 50%;
		    margin-top: -12px;
		    left: 20px;
		}	
	}
	td {
		width: 170px;
		text-align: center;
		margin-left: 44px;
		span {
			display: block;
			padding-bottom: 18px;	
		}		
	}
	td.certification {
		width: 185px;
	}

	.tab-icon {
		display: inline-block;
		width: 40px;
		background: red;
		height: 40px;
	}	
}
th.tab-title,
td.tab-title {
	font-size: 18px;
}
td.tab-plus {
	font-size: 25px;
	padding-bottom: 5px;
}
.f-knob {
	font-weight: 500;
	font-size: 20px;
	width: 200px;
	border-radius: 30px;
}

/*-- SERVICES PAGE -- Second section "Demand" --*/

.demand {
	padding-bottom: 80px;
	.container {
		position: relative;
	}
}
.tomatoTwo {
	position: absolute;
	top: 0;
	left: -370px;
}
.demand-title {
	padding-bottom: 30px;
}
.d-content {
	box-shadow: inset 0 0 0 1px #e8e8e8;
}
.d-cafe,
.d-buffet,
.d-canteen {
	border-bottom: 1px solid #e8e8e8;
}
.d-cafe-slider a {
	max-height: 345px;
	display: inline-block;
}
.d-cafe-slider {
	.slick-arrow {
		background: #000;
		margin-top: -20px;
		padding: 8px 0;
		text-align: center;
		width: 50px;
		opacity: 0.7;
	}
	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
	.slick-prev:hover {
		left: 0;
		padding-right: 3px;	
	}
	.slick-next:hover {
		right: 0;
		padding-left: 3px;
	}
	.slick-disabled {
		opacity: 0.3;
	}
}
.d-info {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-right: 90px;
	p {
		padding-bottom: 15px;		
		span {
			color: $black;			
		}
	}	
}
.d-info__privilege {
	color: $black;
}
.d-info__title	{
	padding-bottom: 20px;
}
.d-info__privilege, 
.d-info__making {
	span {
		display: block;
		padding-bottom: 15px;
	}
}
.d-info__list {
	line-height: 1.4em;
	span {
		display: inline-block;
		width: 7px;
		height: 7px;
		background: #92bf1f;
		margin-right: 8px;
		border-radius: 50%;
	}
}
.d-buffet,
.d-cantina {
	.d-info {
		padding-left: 30px;
		padding-right: 10px;
	}
}