/*-- CONTACTS PAGE -- Second section "Communications" --*/

.communications {
	padding-top: 50px;
	padding-bottom: 125px;
	h4 {
		img {
			padding-bottom: 21px;
    		margin-right: 20px;
    		margin-left: -10px;
		}
	}
	.container {
		position: relative;
	}
}
.olive {
	position: absolute;
	bottom: -85px;
    right: -145px;
}
.communications-title {
	padding-bottom: 30px;
}
address {
	margin-bottom: 35px;
}
.c-phone {
	.phone,
	.mail {
		font-size: inherit;
		font-weight: inherit;
		color: inherit;
		margin-bottom: 35px;
	}
}
.props__list {
	li {
		width: 100%;
		max-width: 395px;
		margin-bottom: 20px;
	}
}
.map {
	margin-top: 20px;
	margin-bottom: 40px;
}
.appeal__title {
	padding-bottom: 23px;
}
.appeal__subteatle {
	font-size: 24px;
	color: $black;
	margin-bottom: 38px;
}
.appeal__knob {
	width: 200px;
}
