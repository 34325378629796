@import 'specification';


/*-- First section "HEADER" --*/

.header {
	background: #d5ebf8 url(../img/offer-bg.jpg) no-repeat top center/cover;
	padding-top: 10px;
	position: relative;
}
.main-menu {
	padding-top: 5px;
	li:after {
		content:'';
		display: block;
		width: 100%;
		height: 2px;
		background: #92bf1f;
		opacity: 0;
		transition: all ease 0.5s;
	}
	li:hover:after {	
		opacity: 1;
	}
	li a,
	li a:hover {
		color: $black;
	}
}
.phone,
.mail {
	color: $black;
	display: inline-block;
}
.phone {
	font:600 18px 'Montserrat', sans-serif;
	margin-bottom: 5px;
}
.phone:hover,
.mail:hover {
	color: $black;
	opacity: 0.6;
}
.offer {
	position: relative;
    margin-top: 40px;
    padding-top: 240px;
    background-image: url(../img/offerT-bg.png);
    background-position: -8px;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 220px;
}
.offer:after,
.offer:before  {
	content: '';
    display: block;
    position: absolute;
    top: 0;
    
}
.offer:after {
	background-image: url(../img/Knife.png);
	background-position: top;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
    right: -115px;    
    width: 115px;
    height: 800px;
}
.offer:before {
	background-image: url(../img/fork.png);
	background-position: top;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	left: -155px;
	width: 140px;
    height: 785px;
}
.offer__title {
	width: 100%;
	max-width: 620px;
	margin: auto;
	line-height: 1em;
}
.offer__subtitle {
	width: 100%;
	max-width: 550px;
	margin:auto;
	font-size: 18px;
	color: $black;
	padding-top: 35px;
	padding-bottom: 45px;
}
.offer__knob {
	width: 300px;
	font-size: 25px;
	font-weight: 500;
	border-radius: 30px;
	padding-top: 13px;
	padding-bottom: 13px;
}
.mint {
	position: absolute;
	top: 30px;
    right: -500px;
}
.seeds {
	bottom: -75px;
	left: -500px;
	position: absolute;
}

/*-- Second section "About" --*/

.about {
	padding-top: 80px;
}
.a-text {
	padding-top: 25px;
}
.a-knob {
	margin-top: 25px;
	width: 200px;
}

/*-- Third section "Privelege" --*/

.privilege {
	padding-top: 80px;
	padding-bottom: 80px;
}
.p-subtitle {
	font-weight: 300;
	font-size: 24px;
	padding-top: 5px;
	margin-bottom: 45px;
}
.p-list {
	padding-bottom: 15px;
}
.p-fact {
	font: 400 45px 'Montserrat',sans-serif;
	color: #35a7df;
	span {
		display:inline-block;
		width: 235px;
	}
}
.p-fact_everyday {
	span {
		display:inline-block;
		width: 135px;
	}
}

/*-- Fourth section "Excellence" --*/

.excellence {
	background: url(../img/e-bg.jpg) no-repeat top center/cover;
	padding: 40px 0;
}
.e-item {
	position: relative;
	margin-top: 55px;
}
.e-item__number {
	position: absolute;
	font:700 60px 'Montserrat',sans-serif;
	color: #35a7df;
	top: -35px;
}
.e-item__title {
	display: inline-block;	
	padding-bottom: 20px;
}
.e-item__title,
.e-item__text {
	margin-left: 55px;
}
.e-item__text {
	line-height: 1.5em;
	span {
		font:400 20px 'Montserrat',sans-serif;
		color: #35a7df;
	}
}
.e-item_four {
	.e-item__text {	
		position: relative;	
		span {
			display: inline-block;
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background: #35a7df;
			position: absolute;
			top: 10px;
    		left: 0;
		}
	}
	.e-item__text:nth-child(4),
	.e-item__text:nth-child(5) {		
		padding-left: 15px;
		margin-top: 5px;
	}
}
.e-tomato {
	position: relative;
}
.tomato {
	position: absolute;
	right: -130px;
    top: -120px;
}
.rosemary {
	position: absolute;
	left: -145px;
    bottom: -130px;
}

/*-- Fifth section "Sentence" --*/

.sentence {
	padding-top: 80px;
	padding-bottom: 85px;
}
.s-title {
	padding-bottom: 25px;
	
}
.s-card {
	padding:25px 20px 30px 20px;
}
.s-card__title {
	color: #828181;
	width: 100%;
	max-width: 300px;
	margin: 20px auto;
}

/*-- Sixth section "Customers" --*/

.customers {
	padding-bottom: 85px;
}

.customers-title {
	padding-bottom: 25px;
}

.reviews {
	width: 100%;
	max-width: 300px;
	margin: auto;
}

.reviews__slider {
	position: relative;
}

.slick-arrow {
	background: transparent;
	border: none;
	position: absolute;
	top: 50%;
	margin-top: -10px;
	z-index: 1;
	cursor: pointer;
	transition: all ease 0.3s;
}
.slick-prev {
	left: -50px;
}
.slick-next {
	right: -50px;
}
.slick-prev:hover {
	left: -53px;
}
.slick-next:hover {
	right: -53px;
}
.slick-disabled {
	opacity: 0.3;
}
.customers-logo {
	padding-left: 40px;
	flex-wrap: wrap;
}
.customers-logo__img {
	width: 33%;
	margin-bottom: 20px;
	img {
		max-width: 100%;
	}
}
.customers-logo__img:last-child {
	margin-bottom: 0;
}
.customers-link {
	border-bottom: 1px solid #828181;
	transition: all ease 0.3s;
	display: inline-block;
	margin-top: 30px;
	color: #828181;
}
.customers-link:hover {
	border-bottom: 1px solid transparent;
	color: #828181;
}

/*-- Seventh section "FOOTER" --*/

.footer {
	padding-top: 15px;
	padding-bottom: 15px;
	border-top: 2px solid #92bf1f;
	background:#f3f3f3;
	.phone,
	.mail {
		color: #828181;
	}
	.container {
		position: relative;
	}
}
.footer-nav {
	border-bottom:  2px solid #cfcfcf;
	padding-bottom: 15px;
	position: relative;
}
.footer-nav__menu {
	li {
		margin-right: 40px;
	}
	a {
		color: #828181;
	}
	a:hover {
		opacity: 0.5;
	}
}
.law {
	padding-top: 15px;
	font-size: 14px;
}
.law__link {
	color: #828181;
}
.leaf {
	position: absolute;
	top: -245px;
    right: -370px;
}

/*Modal Windows*/
.arcticmodal-container_i {
	width: 100%;
	max-width: 820px;
}
.arcticmodal-container_i2 {
    padding: 0;
}
.box-modal {
	position: relative;
	padding:50px 130px;
	background: #fff;
}
.box-modal_close {
	position: absolute;
	top: 20px;
	right: 20px;
	cursor: pointer;
	img {
		transition: all ease 0.5s;
	}	
}
.box-modal_close:hover img {
	opacity: 0.5;
	-moz-transform: rotate(180deg); /* Для Firefox */
    -ms-transform: rotate(180deg); /* Для IE */
    -webkit-transform: rotate(180deg); /* Для Safari, Chrome, iOS */
    -o-transform: rotate(180deg); /* Для Opera */
    transform: rotate(180deg);
}
.modal-title {
	margin-bottom: 50px;
}
.modal-form {
	span {	
		position: relative;
		display: block;
	}
	input {
		margin-bottom: 25px;
	}
	textarea {
		resize: none;
	}

	input.error {
		border-color: red;
	}
}
label.error {
	position: absolute;
	bottom: -25px;
	left: 0;
	font-size: 12px;
	color: red;
}
.form-control {
	padding: 0;
	width: 100%;
	padding-left: 12px;
	padding-top: 15px;
	padding-bottom: 15px;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: auto;
}
.form-control:focus {
    border-color: #92bf1f;
    outline: 0;
    box-shadow: none;
}
.f-knob {
	margin-top: 45px;
	background: transparent;
	cursor: pointer;
}

/*special style*/

.header-secondary {
	background: #fff;
	.navigation {
		border-bottom: 2px solid #92bf1f;
		padding-bottom: 15px;
	}
}
@import 'contacts';
@import 'clientage';
@import 'services';
@import 'media-queries';