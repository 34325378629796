/*-- CLIENTAGE PAGE -- Second section "Clients" --*/

.clients {
	padding-top: 60px;
	padding-bottom: 140px;
	.container {
		position: relative;
	}
}
.boletus {
	position: absolute;
	bottom: -115px;
    right: -180px;
}
.clients-title {
	padding-bottom: 45px;
}
.clients-info {
	padding-bottom: 10px;
	padding-top: 10px;
	border-bottom: 1px solid #dddddd;
}
.clients-info_last {
	padding-bottom: 0;
	border: none;
}
.clients-info__text {
	padding-left: 80px;
}
.clients-info__logo {
	border: 1px solid #dddddd;
	min-width: 200px;
	text-align: center;
	line-height: 160px;
}
.clients-text {
	margin-top: 70px;
} 

