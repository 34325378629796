/*-----Media queries-----*/

@media (min-width: 1200px) {
	.container {
	    max-width: 1180px;
	}
}

/*992px - 1200px*/
@media screen and (max-width:1199px) {

/*-- First section "HEADER" --*/

	.main-menu,
	.phone,
	.mail {
		font-size: 14px;
	}
	.knob {
		width: 100%;
		max-width: 140px;
	}
	.offer {
	    padding-top: 170px;
	    padding-bottom: 146px;
	}
	.offer:before {
	    left: -110px;
	    width: 110px;
	    height: 675px;
	}
	.offer:after {
	    right: -100px;
	    width: 103px;
	    height: 675px;
	    margin-top: 10px;
	}
	h1 {
	    font-size: 44px;
	}
	.offer__knob {
	    max-width: 300px;
	}

/*-- Second section "About" --*/

	.a-knob,
	.f-knob,
	.appeal__knob {
	   max-width: 200px;
	}
/*-- Fourth section "Excellence" --*/

	.tomato,
	.rosemary {
		display: none;
	}

/*-- Fifth section "Sentence" --*/

	h4.s-card__title {
	    font-size: 20px;
	}
	.s-card__pic {
		max-width: 325px !important;
		width: 100%  !important;
		margin: auto;
	}

/*-- Sixth section "Customers" --*/

	.customers-logo__img {
	    width: 30%;
	    margin-left: 15px;
	}

/*--- SERVICES PAGE ---*/

	h2.proffer-title {
	    font-size: 32px;
	    margin-top: 20px;
	}
	.proffer-text {
	    padding-top: 15px;
	}
	.proffer {
	    padding-bottom: 40px;
	    padding-top: 40px;
	}
	.yummy {
	    left: -375px;
	}
	.f-tab {
	    padding: 15px;
	    padding-top: 45px;
	    padding-bottom: 45px;
	    width: 100%;
	    overflow: scroll;
	    th {
		    width: 300px;
		    padding-left: 60px;
		    img {
				left: 10px;
				margin-top: -17px;
		    }
		}
		td, th {
		    font-size: 12px;
		    padding-top: 15px;
		    padding-bottom: 10px;
		}
		td {
		    width: 160px;
		    text-align: center;
		    margin-left: 30px;
		}	
		table {
		    width: 900px;
		}    
	}
	td.tab-title, 
	th.tab-title {
	    font-size: 14px;
	}
	.f-knob {
	     margin-top: 0;
	}
}

/*768px - 992px*/
@media screen and (max-width:992px) {

/*-- First section "HEADER" --*/

	.offer:before,
	.offer:after {
	    display: none;	    
	}

/*-- Second section "About" --*/
	.about {
	    padding-top: 40px;
	}
	.a-title {
		margin-top: 20px;
	}

/*-- Third section "Privelege" --*/

	h4.p-list {
		font-size: 14px;
	}
	.p-fact {
	    font-size: 35px;
	}
	.p-fact span {
	    width: 100%;
	}
	.privilege {
	    padding-top: 40px;
	    padding-bottom: 40px;
	}
	.sentence {
	    padding-top: 40px;
	    padding-bottom: 45px;
	}

/*-- Sixth section "Customers" --*/

	.customers-link {
		margin-bottom: 30px;
	}
	.customers-logo {
	    padding-left: 0;
	}
	.customers {
	    padding-bottom: 45px;
	}

/*-- Seventh section "FOOTER" --*/

	.footer-nav__menu {
		justify-content: space-around;
	}
	.footer-nav__menu li {
	    margin-right: 0;
	}
	.footer {
		.mail, .phone {
		    display: block;
		    max-width: 130px;
		    margin: auto;
		}
		.phone {
		    margin-top: 15px;
		}
	}
	.box-modal {
	    padding: 50px 100px;
	    width: 90%;
	    margin: auto;
	}

/*--- SERVICES PAGE ---*/

	.yummy {
	    display: none;	    
	}
	.case .e-item__text {
	    font-size: 18px;
	}	
	.formats {
		padding-top: 40px;
	}
	.slick-slide img {
	    margin: auto;
	}
	.d-info {
		width: 90%;
		max-width: 480px;
		margin: auto;
		padding-right: 0;
	}
	.d-buffet .d-info, 
	.d-cantina .d-info {
	    padding-left: 0;
	    padding-right: 0;
	}
	.demand {
	    padding-bottom: 40px;
	}
}

/*576px - 768px*/
@media screen and (max-width:767px) {

	/*-- First section "HEADER" --*/

	.phone,
	.mail {
		display: block;
		max-width: 130px;
    	margin: auto;		
	}
	.offer {
		background-image: none;
		padding-top: 80px;
		padding-bottom: 100px;
	}	

/*-- Third section "Privelege" --*/

	h4.p-list {
		font-size: 24px;
	}
	.p-fact {
	    font-size: 45px;
	    margin-bottom: 20px;
	}
	.p-subtitle {
	    font-size: 18px;
	    margin-bottom: 30px;
	}

/*-- Sixth section "Customers" --*/

	.customers-logo__img {
	    width: 45%;
	    margin-left: 0;
	}
	.customers-logo__img:nth-child(even) {
		margin-left: 20px;
	}
	.box-modal {
	    padding: 50px 70px;
	}
	.communications {
		.mail, .phone {
		    max-width: 100%;
		    margin-bottom: 20px;
		}
		.props {
			margin-top: 20px;
		}
	}
	.olive {
	    display: none;	    
	}
	.communications {
	    padding-top: 40px;
	    padding-bottom: 40px;
	}
	.clients-info {
		-webkit-flex-direction: column;
		-moz-flex-direction: column;
		-ms-flex-direction: column;
		-o-flex-direction: column;
		flex-direction: column;
	}
	.clients-info__text {
	    padding-left: 0;
	    text-align: center;
	    margin-top: 20px;	    
	}
	.clients-title {
		text-align: center;
	}	
}

/*320px - 576px*/
@media screen and (max-width:576px) {

/*-- First section "HEADER" --*/

	h1 {
	    font-size: 35px;
	    line-height: 1.3em;
	}
	.offer {
		padding-top: 60px;
		padding-bottom: 60px;
		margin-top: 0;
	}

/*-- Sixth section "Customers" --*/

	.slick-prev {
		left: 0;
	}
	.slick-next {
		right: 0;
	}
	.slick-prev:hover {
		left: -3px;
	}
	.slick-next:hover {
		right: -3px;
	}

/*-- Seventh section "FOOTER" --*/

	.footer-nav__menu {
		flex-direction: column;
   		align-items: center;
   		li {
   			margin-bottom: 15px;
   		}
	}
	.box-modal {
	    padding: 50px 10px;
	    width: 100%;
	}

/*--- SERVICES PAGE ---*/

	.f-tab {
	    padding: 0;
	    padding-top: 20px;
	    padding-bottom: 20px;
	}
	td.tab-title, 
	th.tab-title {
	    font-size: 12px;
	}	
}