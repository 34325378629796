@font-face {
  font-family: 'Raleway';
  src: url('../fonts/raleway-light.eot');
  src: url('../fonts/raleway-light.woff2') format('woff2'),
       url('../fonts/raleway-light.woff') format('woff'),
       url('../fonts/raleway-light.ttf') format('truetype'),
       url('../fonts/raleway-light.svg#raleway-light') format('svg'),
       url('../fonts/raleway-light.eot?#iefix') format('embedded-opentype');
	font-weight: 300;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/raleway-regular.eot');
  src: url('../fonts/raleway-regular.woff2') format('woff2'),
       url('../fonts/raleway-regular.woff') format('woff'),
       url('../fonts/raleway-regular.ttf') format('truetype'),
       url('../fonts/raleway-regular.svg#raleway-regular') format('svg'),
       url('../fonts/raleway-regular.eot?#iefix') format('embedded-opentype');
	font-weight: 400;
}

@font-face {
  font-family: 'Raleway';
  src: url('../fonts/raleway-medium.eot');
  src: url('../fonts/raleway-medium.woff2') format('woff2'),
       url('../fonts/raleway-medium.woff') format('woff'),
       url('../fonts/raleway-medium.ttf') format('truetype'),
       url('../fonts/raleway-medium.svg#raleway-medium') format('svg'),
       url('../fonts/raleway-medium.eot?#iefix') format('embedded-opentype');
	font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-regular.eot');
  src: url('../fonts/montserrat-regular.woff2') format('woff2'),
       url('../fonts/montserrat-regular.woff') format('woff'),
       url('../fonts/montserrat-regular.ttf') format('truetype'),
       url('../fonts/montserrat-regular.svg#montserrat-regular') format('svg'),
       url('../fonts/montserrat-regular.eot?#iefix') format('embedded-opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-semibold.eot');
  src: url('../fonts/montserrat-semibold.woff2') format('woff2'),
       url('../fonts/montserrat-semibold.woff') format('woff'),
       url('../fonts/montserrat-semibold.ttf') format('truetype'),
       url('../fonts/montserrat-semibold.svg#montserrat-semibold') format('svg'),
       url('../fonts/montserrat-semibold.eot?#iefix') format('embedded-opentype');
	font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/montserrat-bold.eot');
  src: url('../fonts/montserrat-bold.woff2') format('woff2'),
       url('../fonts/montserrat-bold.woff') format('woff'),
       url('../fonts/montserrat-bold.ttf') format('truetype'),
       url('../fonts/montserrat-bold.svg#montserrat-bold') format('svg'),
       url('../fonts/montserrat-bold.eot?#iefix') format('embedded-opentype');
	font-weight: 700;
}